import { render, staticRenderFns } from "./MnemonicPhraseTable.vue?vue&type=template&id=010c0e56&scoped=true&"
import script from "./MnemonicPhraseTable.vue?vue&type=script&lang=js&"
export * from "./MnemonicPhraseTable.vue?vue&type=script&lang=js&"
import style0 from "./MnemonicPhraseTable.vue?vue&type=style&index=0&id=010c0e56&lang=scss&scoped=true&"
import style1 from "./MnemonicPhraseTable.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "010c0e56",
  null
  
)

export default component.exports